<template>
    <div class="new">




        <header>
            <div class="">
                <h1>{{title}}</h1>
            </div>

            <div class="">
                <select class="language-options" name="language" v-model="language">
                    <option value="null">Select language</option>
                    <option v-for="l in languages" :key="l.code" :value="l.code">{{l.name}}</option>
                </select>
                <lang-flag :lang="language" />
            </div>

        </header>


        <MarkdownEditor v-model="content" class="editor" autofocus/>


        <ul class="errors" v-if="errors.length">
            <li v-for="(e,i) in errors" :key="i">{{e}}</li>
        </ul>

        <footer>
            <div class="actions">
                <button type="submit" @click="publish">Publish</button>
            </div>

            <p class="text-muted">
                To add links and format the entry you can use <a href="https://daringfireball.net/projects/markdown/syntax">Markdown</a>
            </p>
        </footer>




    </div>
</template>

<script>
import MarkdownEditor from '@voraciousdev/vue-markdown-editor'

export default {
    name: 'New',
    components: {
        MarkdownEditor
    },
    props: {

    },
    data() {
        return {
            content: '# ',
            language: null,
            languages: [],
            errors: []
        }
    },
    computed: {
        title: function() {
            let title = this.parseTitle(this.content);

            return title == "" ? "New entry" : title;
        }
    },
    mounted() {
        this.getLanguages();
    },

    methods: {

        parseTitle(content) {
            const h1 = /^# (.*$)/im;
            let matches = this.content.match(h1)
            let title = (matches && matches.length == 2) ? matches[1] : "";

            return title;
        },
        prepareMarkdown(content) {
            const h1 = /^# (.*$)/im;

            return content.replace(h1, "").trimStart().trimEnd();
        },

        getLanguages() {
            this.$api.get('/languages/')
            .then((response) => {

                this.languages = response.data.data;

            })
            .catch((response) => {
                console.log(response);
            });
        },

        publish(evt) {
            evt.preventDefault()

            // Validation
            this.errors = []
            let title = this.parseTitle(this.content);
            let markdown = this.prepareMarkdown(this.content)

            if (!this.language) this.errors.push('You must choose a language.')
            if (title == "") this.errors.push('The title cannot be empty.')
            if (markdown == "") this.errors.push('The entry should at least contain something...')

            if (this.errors.length != 0) return;

            if( !confirm("Are you sure? Posts cannot be edited once published.") ) return;



            this.$api.post('/posts/', {
                language: this.language,
                title: title,
                markdown: markdown,
            })
            .then((response) => {
                let post = response.data.data;

                console.log(post);

                this.$router.replace({ name: 'post', params: { username: post.author.data.username, key: post.key }});

            })
            .catch((response) => {
                console.log(response);
            });


        },
    }


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

header,
footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

footer {
    padding-top: 3em;
}

.language-options {
    margin-right: 1em;
}


.editor {
    background-color: #050505;
    border-radius: 0.25em;
    padding: 1em;
    font-family: '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !important;
}


</style>
