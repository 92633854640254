<template>
    <div class="posts">


        <h1>Entries ({{posts.length}})</h1>


        <section>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>🌐</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post in posts" :key="post.key">
                        <td>{{post.created_at | moment("D/M/Y")}}</td>
                        <td><router-link :to="{ name: 'post', params: { username: post.author.data.username, key: post.key }}">{{post.title}}</router-link> <span class="corrected">{{post.corrected ? "✅" : ""}}</span></td>
                        <td><lang-flag :lang="post.language" /></td>
                        <td><a class="button" href="#" @click="deletePost(post)">Delete</a></td>
                    </tr>
                </tbody>
            </table>

        </section>




    </div>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'Posts',
    components: {

    },
    data() {
        return {
            posts: []
        }
    },
    mounted() {

        this.$api.get('/users/'+this.$store.getUser().username+'/posts')
        .then((response) => {
            this.posts = response.data.data;
        })
        .catch((response) => {
            console.log(response);
        });

    },
    methods: {

        deletePost(post) {
            if( !confirm("Do you really want to delete this post?") ) return;

            this.$api.delete('/posts/'+post.key)
            .then((response) => {
                this.posts = this.posts.filter( p => p.key != post.key );
            })
            .catch((response) => {
                console.log(response);
            });

        }

    }
}
</script>


<style lang="scss" scoped>

table {
    width: 100%;
    text-align: left;
}
table tr td:last-child,
table tr th:last-child {
    text-align: right;
}

td a {
    vertical-align: middle;
}
.corrected {
    font-size: 0.4rem;
    vertical-align: middle;
}


</style>
