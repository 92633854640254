<template>
    <div class="login">

        <h1>Login</h1>

        <form @submit="onSubmit" class="mb-3">

            <input type="text" name="username" v-model="form.username" placeholder="Username" trim/>
            <input type="password" name="password" v-model="form.password" placeholder="Password" trim/>

            <button type="submit" variant="primary">Login</button>

        </form>

        <ul class="errors">
            <li v-for="(e,i) in errors" :key="i">{{e}}</li>
        </ul>



    </div>
</template>


<script>


export default {
    name: 'Login',
    components: {
    },
    data() {
        return {
            form: {
                username: process.env.VUE_APP_TEST_USERNAME ? process.env.VUE_APP_TEST_USERNAME : '',
                password: process.env.VUE_APP_TEST_PASSWORD ? process.env.VUE_APP_TEST_PASSWORD : '',
            },
            errors: []
        }
    },
    mounted() {

    },
    methods: {

        onSubmit(evt) {
            evt.preventDefault()

            // Validation
            this.errors = []

            if (!this.form.username) this.errors.push('Username is missing.')
            if (!this.form.password) this.errors.push('Password is missing.')

            if (this.errors.length == 0) {

                this.loginUser();

            }
        },
        loginUser() {
            this.$api.post('/login', {}, {
                auth: {
                    username: this.form.username,
                    password: this.form.password
                }
            })
            .then((response) => {
                this.$store.setToken(response.data.token);
                this.$store.setUser(response.data.user)

                this.$api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

                this.$router.replace('/new');
            })
            .catch((error) => {

                if (error.response) {
                    let code = error.response.status;

                    if (code == 401) {
                        this.errors.push('The credentials are not correct.')
                    }
                    else if (code == 404) {
                        this.$emit("register",this.form)
                    }
                    else {
                        this.errors.push('An error has occurred. Contact the magicians.')
                    }
                }
                else {
                    this.errors.push('An error has occurred. Contact the magicians.')
                }
            });

        }



    }
}
</script>



<style scoped lang="scss">


.login input {
    margin-right: 1em;
}


</style>
